<template lang="html">
    <MyneoPrivacyPolicySectionCn />
  </template>
  
  <script>
  import MyneoPrivacyPolicySectionCn from "../components/privacypolicy/myneo/MyneoPrivacyPolicySectionCn.vue";
  
  export default {
    components: {
      MyneoPrivacyPolicySectionCn,
    },
    created() {
      // this.$store.commit("muttHideHeader", "wa");
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .eula {
    text-align: justify;
  
    b {
      font-weight: bold;
    }
  }
  .inner {
    @media screen and (max-width: 599px) {
      margin: 20px 0 40px;
    }
  }
  </style>
  