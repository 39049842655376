<template>
    <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
        <div class="inner">
            <p class="eula">
                <b>myneo 隐私权政策说明</b><br />
                <b>更新日期: 2023 年 7 月 14 日</b> <br><br>
                本隐私政策内容适用于戴耐德语言软件系统开发（北京）有限公司的移动客户端myneo提供的功能与服务。

                <br /><br /><b>myneo隐私权政策</b><br /><br />
               <b>隐私权政策将涉及您与我们互动时myneo可能会随时收集和使用您的个人信息，例如：当您访问我们的网站、当您使用myneo产品和服务或当您联系我们的销售代表时。为实现业务功能，终端用户授权同意开启“读写SD卡权限”、“网络访问权限”、“麦克风权限”或“录制音频权限”、“前置摄像头权限”。如果终端用户不想被收集上述信息，可以通过关闭“读写SD卡权限”、“网络访问权限”、“麦克风权限”、“前置摄像头权限”实现</b><br />
                第三方SDK或相关技术获取用户信息情况披露:
                <br><br />
            <ul style="margin-left:18px;">
                <li style="list-style-type: number;"><b>Bugsnag
                    收集用于收集Android端和iOS端的myneo报错信息，需要收集事件发生时以下信息：设备上电池电量、设备品牌、设备是否充电、设备在DPI的屏幕密度比例信息、设备是否为模拟器、设备位置服务、设备当前网络类型和设备的屏幕分辨率。</b>
                </li>
                <li style="list-style-type: number;"><b>百度baidu push SDK提供百度推送通知服务,收集设备属性信息(硬件型号，操作系统版本及系统情况，Android
                    ID（仅Android）、设备连接信息（例如电信运营商、WIFI信息）) 。
                    Baidu push sdk的隐私协议链接https://push.baidu.com/doc/guide/baidu_legal。</b></li>
                <li style="list-style-type: number;"><b>声网agora SDK 提供视频通话服务，使用过程中可能收集麦克风和摄像头信息、网络类型、用户属性、频道属性等信息
                    声网隐私合规链接https://www.agora.io/cn/privacy-policy。</b></li>
            </ul>

            <br /><b>我们为什么收集个人信息？</b><br /><br />
            <b>myneo收集个人信息，是为了改善我们的服务和支持。</b>此外，您的个人信息可帮助我们及时通知您关于产品通知、产品更新和优惠活动信息。
            如果您已订阅myneo的电子邮件，myneo将定期向您发送最新的产品和服务信息。您也可以随时取消订阅。<br>

            <br /><b>我们收集哪些个人信息？</b><br /><br />
            <b>您的个人信息可帮助我们以多种方式提高服务水平。因此，我们可能收集相关的个人信息，例如您的姓名、电话号码、电子邮箱地址、产品购买日期等与您使用的myneo产品相关的信息，以及与支持或服务有关的信息。</b><br>

            <br /><b>我们会公开您的个人信息吗？</b><br /><br />
            <b>myneo非常尊重您的隐私。当您使用myneo的产品或服务时，您将您的信息交与我们。我们不会与myneo以外的任何公司、组织或个人分享您的个人信息，除非获得您的允许或有相关法律要求公开。</b>

            <br /><br /><b>我们如何保护您的个人信息？</b><br /><br />
            myneo通过采取预防措施（包括行政/技术措施和物理安全措施）保护您的个人信息，防止您的个人信息丢失、被盗、被滥用或被非法侵入、披露、更改或销毁。

            <br /><br /><b>个人信息的注销</b><br /><br />
            <b>我们保留最少的个人信息，以允许对我们的服务进行访问并验证获得的证书。如果您希望“选择退出”并从我们的数据库中删除您的个人身份信息，您可以向 legal@nexgenenglishonline.co
            发送申请邮件并要求删除您的个人信息，并声明“我已阅读、理解并同意删除个人身份信息条款。”除非我们需要保留您的个人身份信息以遵守适用的法律义务或解决争议，否则通常会在收到您的请求后的 1 个工作日内删除您的个人身份信息。
            如果您在课程完成前要求删除您的个人身份信息，您将无法继续学习。此外，如果您已经收到了 Neo 证书，并且您稍后要求删除您的个人身份信息，那么我们将无法处理任何后续的证书验证请求，因为我们将不再拥有您的信息。</b>

            <br /><br /><b>隐私权问题</b><br /><br />
            如果您对我们的客户隐私权政策或数据处理有任何疑问，请发送邮件至
            <b>legal@nexgenenglishonline.co</b>
            </p>
        </div>
    </section>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
</style>